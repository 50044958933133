import React from "react"
import styled from "styled-components";

import { NavLink, Title3 } from "../recipes";

import Layout from "../components/Layout"
import InstagramLink from "../components/InstagramLink"

const Links = styled(Title3)`
  display: flex;
  flex-direction: column;
  align-items: center;
  line-height: 200%;
`;

const Page = () => (
  <Layout>
    <Links>
      <NavLink to="/cupcakes">cupcakes</NavLink>
      <NavLink to="/cakes">cakes</NavLink>
      <NavLink to="/flavours">flavours</NavLink>
      <NavLink to="/cookies">cookies</NavLink>
      <NavLink to="/about">about</NavLink>
      <NavLink to="/contact">contact</NavLink>
      <InstagramLink />
    </Links>
  </Layout>
);

export default Page;
